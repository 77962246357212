@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-slide{
    background-size: cover;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover !important;
    background-position: center !important;
}